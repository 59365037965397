const PopupControl = () => {
  let cacheMap = {};

  const displayPopups = (data) => {
    const {
      id,
      title,
      type,
      questions,
      options,
      choices,
      bottom,
      left,
      startTime,
      time,
    } = data;
    const item = cacheMap[id];
    // console.log(`inside displayPopus |works`);

    if (!item) return;
    // You can update the content or style of the div as needed.
    // For example, if you want to change the text content of the div:
    // item.style.position = "absolute"
    item.style.bottom = `${bottom}%`;
    item.style.left = `${left}%`;


    const ground = document.getElementById("player-ground-1");
    // item = document.createElement("div");
    item.setAttribute("id", id);
    // item.classList.add("popups");
    ground.appendChild(item);
    cacheMap[id] = item;

    item.className = "btn btn-transparent popups";
    item.style.display = "block";
    // item.style.position = "absolute"
    item.style.bottom = `${bottom}%`;
    item.style.left = `${left}%`;
    item.style.borderRadius = "0px";
    item.style.backgroundImage =
      "linear-gradient(to bottom left, rgba(99, 16, 78,0.52), rgba(28, 12, 51,0.72))";

    if (type === "poll" || type === "ads") {
      item.textContent = `${title}`;


      // Create a div for choices
      const optionsDiv = document.createElement("div");
      // Apply styles to control layout
      optionsDiv.className = "d-flex justify-content-center align-items-center";
      optionsDiv.style.display = "flex"; // Make choices appear in a row
      optionsDiv.style.flexDirection = "row"; // Stack choice
      optionsDiv.style.borderRadius = "0px";
      // optionsDiv.style.border = "1px solid red";
      item.appendChild(optionsDiv);

      // Loop through choices and create buttons
      options?.forEach((option) => {
        //  console.log(`type=${type} option=${option.text} working`);
        const optionButton = document.createElement("button");
        optionButton.className = `btn btn-primary m-2 ${option.optId}`;
        optionButton.textContent = option.text;
        optionButton.style.borderRadius = "0px";
        // optionButton.style.border = "1px solid white";
        // optionButton.style.boxShadow = "4px 4px 9px 0px rgba(161,152,115,0.5)";
        optionButton.style.backgroundImage =
          "linear-gradient(to bottom, rgba(99, 16, 78,0.32), rgba(28, 12, 51,0.1))";
        optionsDiv.appendChild(optionButton);
        optionButton.addEventListener("click", () => {
          // Handle the click event here
          console.log(`${option.text} clicked`);
          // alert(`Ad skipped`);
        });
      });
    } else if (type === "quiz") {
      item.textContent = `${questions[0].question}`;

      // Create a div for choices
      const choicesDiv = document.createElement("div");
      // Apply styles to control layout
      choicesDiv.className = "d-flex justify-content-center align-items-center";
      choicesDiv.style.display = "flex"; // Make choices appear in a row
      choicesDiv.style.flexDirection = "row"; // Stack choice
      choicesDiv.style.borderRadius = "0px";
      item.appendChild(choicesDiv);

      // Loop through choices and create buttons
      data?.questions[0]?.choices?.forEach((choice) => {
        const choiceButton = document.createElement("button");
        choiceButton.className = "btn btn-primary m-2";
        // console.log(`type=${type} choice=${choice.text} working`);
        choiceButton.textContent = choice.text;
        choiceButton.style.borderRadius = "0px";
        choiceButton.style.backgroundImage =
          "linear-gradient(to bottom left, rgba(99, 16, 78,0.52), rgba(28, 12, 51,0.72))";
        choicesDiv.appendChild(choiceButton);
        choiceButton.addEventListener("click", () => {
          // Handle the click event here
          console.log(`${choice.text} clicked`);
          // alert(`Ad skipped`);
        });
      });
    } else {
      item.textContent = `${type}`;
    }
  };

  const enter = (data) => {
    const { id, type,title, questions, options, bottom, left, startTime, time } =
      data;
    let item = cacheMap[id];
    // console.log(`inside enter |works`);

    if (item) {
      item.style.display = "block";
      // item.style.position = "absolute"
      item.style.bottom = `${bottom}%`;
      item.style.left = `${left}%`;
      item.style.borderRadius = "9px";
      // item.style.backgroundImage =
      //   "linear-gradient(to bottom left, rgba(30, 10, 48,0.52), rgba(92, 16, 75,0.72))";
      // item.style.top = "16%";
      // item.zIndex = "3";
      // item.textContent = `hello${type}`;
      // console.log(`enter has item`);
      displayPopups(data)
    } else {
      // console.log(`enter doesnt has item`);
      const ground = document.getElementById("player-ground-1");
      item = document.createElement("div");
      item.setAttribute("id", id);
      // item.classList.add("popups");
      ground.appendChild(item);
      cacheMap[id] = item;
      item.className = "btn btn-primary popups";
      item.style.display = "block";
      // item.style.position = "absolute"
      item.style.bottom = `${bottom}%`;
      item.style.left = `${left}%`;
      item.style.borderRadius = "9px";
      // item.style.backgroundImage =
      //   "linear-gradient(to bottom left, rgba(99, 16, 78,0.52), rgba(28, 12, 51,0.72))";

      if (type === "Skip Ad") {
        item.addEventListener("click", () => {
          // Handle the click event here
          console.log(`Ad skipped`);
          // alert(`Ad skipped`)
        });
      } else if (type === "quiz" || type === "poll" || type === "ads") {
        // Create the questions and choices
        item.className = "btn btn-transparent popups questions";
        item.style.borderRadius = "0px";

        if (questions) {
          // Create a div for the questions
          const questionDiv = document.createElement("div");
          questionDiv.textContent = questions;
          // questionDiv.style.borderRadius = "0px";
          item.appendChild(questionDiv);

          questionDiv.style.marginBottom = "10px"; // Add some space between questions and choices
        } else {
          item.textContent = `${type}`;
        }
      }
    }
  };

  const update = (data) => {
    displayPopups(data)
  };

  const leave = (data) => {
    const { id, startTime, endTime, time } = data;
    const item = cacheMap[id];
    console.log(`inside leave |works /startTime/endTime`,startTime,endTime,`item`,item);
    if (!item) return;
    if (time > endTime || time < startTime) {
      item.style.display = "none";
    } else {
      item.style.display = "block";
    }
  };

  const destroy = () => {
    console.log(`inside destroy |works`);
    const ground = document.getElementById("player-ground-1");
    for (const id in cacheMap) {
      ground.removeChild(cacheMap[id]);
    }
    cacheMap = {};
  };

  return { enter, update, leave, destroy };
};

export default PopupControl();
