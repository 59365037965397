import { create } from "zustand";
import firebase from "firebase";
// import admin from "firebase";
import { authType, usersType } from "./types";
import axios from "axios";

const timestamp = firebase.firestore.FieldValue.serverTimestamp();

const useNotificationStore = create((set, get) => ({
  notifications: [],
  addNotification: (notification) => {
    const messaging = firebase.messaging();
    messaging
      .getToken()
      .then((token) => {
        console.log(token);
      })
      .catch((error) => {
        console.log(`error in notification`, error.message);
      });
  },
  // Function to send bulk notifications
  sendNotificationToMultipleTokens: async (title, body,item) => {
    firebase
      .firestore()
      .collection("users")
      .get()
      .then(async (snaps) => {
        let results = await snaps.docs.map((doc) => doc.data());
        // let usersOnly = results.filter((users) => users.usertype !== "admin");
        let usersOnly = await results.filter(
          (users) => users.username === "mustapha"
        );

        // const allFcmTokens = await results.reduce((accumulator, current) => {
        //   return accumulator.concat(current.fcmTokens);
        // }, []);
        const allFcmTokens = await usersOnly.reduce((accumulator, current) => {
          return accumulator.concat(current.fcmTokens);
        }, []);
        console.log(`allFcmTokens`,allFcmTokens);
        

        axios
          .post("https://nodepushfirebase.onrender.com/send", {
          // .post("http://192.168.70.62:8080/send", {
            fcmtoken: allFcmTokens,
            notification: {
              title: title,
              body: body,
            },
            data: item, //channel details item
            // data: {
            //   screen: "screenname",
            //   item: item,
            //   channelId: item.channelId,
            //   title: item.title.en,
            //   url: item.url,
            //   kind: "channel",
            // },
          })
          .then((response) => {
            console.log("Notification sent successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error sending notification:", error);
          });

        console.log("users success");
        set({
          usersLoading: false,
          usersStatus: usersType.GETUSERS_SUCCESS,
          usersList: results,
        });
      });
  },
}));

export default useNotificationStore;
