import React, { useEffect } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CDataTable,
  CBadge,
} from "@coreui/react";
import moment from "moment";

const ProfileInteractions = ({
  profileInteractions,
  selectedInteractivity,
}) => {
  // Function to transform data as needed
  const transformData = (data) => {
    if (!data) return [];

    return data.map((interaction) => {
      // Handle 'quiz' type interactions
      if (interaction?.type === "quiz") {
        const selectedOptions = Object.keys(interaction)
        .filter((key) => key.startsWith("question-"))
        .sort((a, b) => interaction[a].answerTime - interaction[b].answerTime) // Sort by answerTime
        .map((questionKey, index) => {
          const questionData = interaction[questionKey];
          return `${index + 1}. ${questionData.questionId} - ${questionData.selected} - ${new Date(
            questionData.answerTime * 1000
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
          })}`;
        })
        .join("\n");          
        const selectedOptionsLocation = Object.keys(interaction)
          .filter((key) => key.startsWith("question-"))
          .sort((a, b) => interaction[a].answerTime - interaction[b].answerTime)
          .map((questionKey, index) => {
            const questionData = interaction[questionKey];
            return `${index+1}. ${
              !questionData.location ||
              questionData.location === undefined ||
              questionData.location === "null,null"
                ? "No location data"
                : questionData.location
            }`;
          }).join("\n")

        return {
          ...interaction,
          selected: selectedOptions,
          location: selectedOptionsLocation,
        };
      }

      // Handle 'poll' type interactions
      if (interaction?.type === "poll") {
        return {
          ...interaction,
          selected: interaction.selected, // This already holds the selected optionId
        };
      }

      // Handle 'ads' type interactions
      if (interaction?.type === "ads") {
        const selectedOptions = Object.keys(interaction)
          .filter((key) => key.startsWith("click-"))
          .map((clickKey) => {
            const clickTime = interaction[clickKey]?.[0]?.seconds;
            const timeString = clickTime
              ? new Date(clickTime * 1000).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "No Time";
            return `${clickKey.split("-")[1]}-${timeString}`;
          })
          .join(", ");

        return {
          ...interaction,
          selected: selectedOptions,
        };
      }

      return interaction;
    });
  };

  // Determine fields based on interaction type
  const interactionData = transformData(
    profileInteractions?.[selectedInteractivity?.interactiveId]
  );
  const isQuiz = interactionData?.[0]?.type === "quiz";

  const fields = [
    { key: "userName", label: "User name" },
    { key: "profileName", label: "Profile name" },
    { key: "type", label: "Type" },
    {
      key: "selected",
      label: isQuiz ? "Question-Option-Time" : "Option-Time",
    },
    { key: "location", label: "Location" },
    { key: "updatedAt", label: "Updated At" },
  ];

  // Include the 'location' field only if it's not a quiz
  // if (!isQuiz) {
  //   fields.push({ key: "location", label: "Location" });
  // }

  useEffect(() => {
    console.log(
      `transformData(
            profileInteractions?.[selectedInteractivity?.interactiveId]
          )`,
      interactionData
    );
  }, [profileInteractions, selectedInteractivity]);

  return (
    <CCard>
      <CCardHeader>Profile Interactions</CCardHeader>
      <CCardBody>
        <CDataTable
          items={interactionData}
          fields={fields}
          scopedSlots={{
            userName: (item) => (
              <td>{item.userName ? item.userName : "No User Name"}</td>
            ),
            profileName: (item) => (
              <td>{item.profileName ? item.profileName : "No Profile Name"}</td>
            ),
            selected: (item) => (
              <td>
              <pre className="text-white">
              {item.selected ? item.selected : "No Profile Name"}
              </pre>
              </td>
            ),
            location: (item) => (
              <td>
              <pre className="text-white">
              {!item.location || item.location==="null,null" ? "No Location Data":item.location }
              </pre>
              </td>
            ),
            updatedAt: (item) => (
              <td>
                <CBadge>
                  {item?.updatedAt
                    ? moment(item.updatedAt?.toDate()).format(
                        "MMMM D, YYYY, h:mm:ss A"
                      )
                    : ""}
                </CBadge>
              </td>
            ),
          }}
          hover
          striped
          bordered
          size="sm"
          pagination
        />
      </CCardBody>
    </CCard>
  );
};

export default ProfileInteractions;
