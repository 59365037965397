import { create } from "zustand";
import firebase from "firebase";
import { channelsType, channelGroupsType, reviewsType } from "./types";
import sendNotificationToMultipleTokens from "./Notifications";
import useNotificationStore from "./Notifications";


const timestamp = firebase.firestore.FieldValue.serverTimestamp();


const useChannelsStore = create((set, get) => ({
  channels: [],
  channelReviews: [],
  channel: {},
  channelsData: [],
  currentChannel: [],
  channelsStatus: "",
  channelsError: "",
  //Channel Groups
  channelGroups: [],
  channelGroupsData: [],
  channelGroupsStatus: "",
  channelGroupsError: "",
  //Channel Review
  channelReviewStatus: "",
  channelReviewError: "",
  //groupChannels
  groupChannels: [],
  searchedChannels: [],
  
  getChannels: () => {
    set({ channelsStatus: channelsType.GETCHANNELS_LOADING });

    firebase
      .firestore()
      .collection("channels")
      .orderBy("createdAt", "desc")
      .get()
      .then((snaps) => {
        let results = snaps.docs.map((doc) => doc.data());
        console.log(`getChannels success`);
        let newData = [];

        results.forEach((item) => {
          newData[item.channelId] = item;
        });

        set({
          channels: results,
          channelsData: {
            ...get().channelsData,
            ...newData,
          },
          channelsStatus: channelsType.GETCHANNELS_SUCCESS,
        });
      })
      .catch((error) => {
        set({
          channelsStatus: channelsType.GETCHANNELS_FAIL,
          channelsError: error.message,
        });
      });
  },
  getChannel: (channelId) => {
    set({ channelsStatus: channelsType.GETCHANNEL_LOADING });

    firebase
      .firestore()
      .collection("channels")
      .doc(channelId)
      .get()
      .then((snap) => {
        let result = snap.data();
        console.log(`getChannel success`);
        set({
          channel: result,
          channelsStatus: channelsType.GETCHANNEL_SUCCESS,
        });
      })
      .catch((error) => {
        set({
          channelsStatus: channelsType.GETCHANNEL_FAIL,
          channelsError: error.message,
        });
      });
  },
  //create Channel
  createChannel: async (updates) => {
    const uid = firebase.auth().currentUser?.uid;
    set({ channelsStatus: channelsType.CREATECHANNEL_LOADING });

    const newChannel = async (updates) => {
      let ref = firebase.firestore().collection("channels").doc();
      
      updates = {
        channelId: ref.id,
        ...updates,
        authorId: uid,
        createdAt: timestamp,
        updatedAt: timestamp,
      };

      const notificationItem= {
        "kind":updates.kind,
        "channelId":updates.channelId,
        "url":updates.url,
        "title":updates.title.en,
    }

    
      ref
        .set(updates)
        .then(() => {
          set({ channelsStatus: channelsType.CREATECHANNEL_SUCCESS });
          console.log(`channel created successfully`);
          useNotificationStore.getState().sendNotificationToMultipleTokens(`New Channel ${updates.title.en}`,`Channel ${updates.title.en} has been added`,notificationItem)
        })
        .catch((error) => {
          console.log(`create channel error`, error.message);
          set({ channelsStatus: channelsType.CREATECHANNEL_SUCCESS });
        });
    };

    if (
      updates.imageLogo instanceof File ||
      updates.imagePreview instanceof File
    ) {
      var storageRef = firebase.storage().ref();

      let imageLogoPromise = null;
      let imagePreviewPromise = null;

      const logofileName = `${updates.title.en}-logo`;
      const previewfileName = `${updates.title.en}-preview`;
      var imageLogoRef = storageRef.child("channelImages/" + logofileName);
      var imagePreviewRef = storageRef.child(
        "channelImages/" + previewfileName
      );

      if (updates.imageLogo instanceof File) {
        var imageLogo = updates.imageLogo; // Change to image variable

        imageLogoPromise = imageLogoRef
          .put(imageLogo)
          .then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then(async (url) => {
              // newChannel({ ...updates, imageLogo: url || null });
              updates.imageLogo = (await url) || "null";
            });
          });
      }
      if (updates.imagePreview instanceof File) {
        var imagePreview = await updates.imagePreview; // Change to image variable

        imagePreviewPromise = imagePreviewRef
          .put(imagePreview)
          .then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then(async (url) => {
              // newChannel({ ...updates, imagePreview: url || null });
              updates.imagePreview = (await url) || "null";
            });
          });
      }

      // Wait for both uploads to complete
      await Promise.all([imageLogoPromise, imagePreviewPromise].filter(Boolean))
        .then(() => {
          // Update the channel only after images are uploaded
          return newChannel(updates);
        })
        .then(() => {
          console.log("image url successfull");
        })
        .catch((error) => {
          console.log(`image url error`, error.message);
        });
    } else {
      await newChannel(updates);
    }
  },
  //update Channel
  updateChannel: async (channelId, updates) => {
    set({ channelsStatus: channelsType.UPDATECHANNEL_LOADING });

    const updateChannelFuntion = (channelId, updates) => {
      firebase
        .firestore()
        .collection("channels")
        .doc(channelId)
        .update({
          ...updates,
          updatedAt: timestamp,
        })
        .then(() => {
          set({ channelsStatus: channelsType.UPDATECHANNEL_SUCCESS });
          console.log(`update channel successful`);
        })
        .catch((error) => {
          console.log(`channel update error`, error.message);
          set({ channelsStatus: channelsType.UPDATECHANNEL_FAIL });
        });
    };

    if (
      updates.imageLogo instanceof File ||
      updates.imagePreview instanceof File
    ) {
      var storageRef = firebase.storage().ref();

      let imageLogoPromise = null;
      let imagePreviewPromise = null;

      const logofileName = `${updates.title.en}-logo`;
      const previewfileName = `${updates.title.en}-preview`;
      var imageLogoRef = storageRef.child("channelImages/" + logofileName);
      var imagePreviewRef = storageRef.child(
        "channelImages/" + previewfileName
      );

      if (updates.imageLogo instanceof File) {
        var imageLogo = updates.imageLogo; // Change to image variable

        imageLogoPromise = imageLogoRef
          .put(imageLogo)
          .then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then(async (url) => {
              // newChannel({ ...updates, imageLogo: url || null });
              updates.imageLogo = (await url) || "null";
            });
          });
      }
      if (updates.imagePreview instanceof File) {
        var imagePreview = await updates.imagePreview; // Change to image variable

        imagePreviewPromise = imagePreviewRef
          .put(imagePreview)
          .then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then(async (url) => {
              // newChannel({ ...updates, imagePreview: url || null });
              updates.imagePreview = (await url) || "null";
            });
          });
      }

      // Wait for both uploads to complete
      await Promise.all([imageLogoPromise, imagePreviewPromise].filter(Boolean))
        .then(() => {
          // Update the channel only after images are uploaded
          return updateChannelFuntion(channelId, updates);
        })
        .then(() => {
          console.log("image url successfull");
        })
        .catch((error) => {
          console.log(`image url error`, error.message);
        });
    } else {
      await updateChannelFuntion(channelId, updates);
    }

    // updateChannelFuntion(channelId, updates);
  },
  deleteChannel: async (channelId, channelDetails) => {
    set({ channelsStatus: channelsType.DELETECHANNEL_LOADING });

    const deleteChannelFunction = (channelId) => {
      firebase
        .firestore()
        .collection("channels")
        .doc(channelId)
        .delete()
        .then(() => {
          console.log(`delete channel successfull`);
          set({ channelsStatus: channelsType.DELETECHANNEL_SUCCESS });
          window.location.reload();
        })
        .catch((error) => {
          console.log(`delete channel error`);
          set({
            channelsStatus: channelsType.DELETECHANNEL_FAIL,
            channelsError: error.message,
          });
        });
    };

    if (channelDetails.imageLogo !== "" || channelDetails.imagePreview !== "") {
      let imageLogoPromise = null;
      let imagePreviewPromise = null;
      if (channelDetails.imageLogo !== "") {
        imageLogoPromise = firebase
          .storage()
          .refFromURL(channelDetails?.imageLogo)
          .delete()
          .then(() => {
            console.log("image logo deleted from storage");
            get().updateChannel(channelId, { imageLogo: "" });
          })
          .catch((error) => {
            console.log("image logo not deleted from storage", error.message);
          });

        // channelDetails.imageLogo=""
      }
      if (channelDetails.imagePreview !== "") {
        imagePreviewPromise = firebase
          .storage()
          .refFromURL(channelDetails?.imagePreview)
          .delete()
          .then(() => {
            console.log("image preview deleted from storage");
            get().updateChannel(channelId, { imagePreview: "" });
          })
          .catch((error) => {
            console.log(
              "image preview not deleted from storage",
              error.message
            );
          });
        // channelDetails.imagePreview=""
      }
      // Wait for both images to delete to complete
      await Promise.all([imageLogoPromise, imagePreviewPromise].filter(Boolean))
        .then(() => {
          // delete channel
          return deleteChannelFunction(channelId);
        })
        .then(() => {
          console.log("delete image url successfull");
        })
        .catch((error) => {
          console.log(`image url error`, error.message);
        });
    } else {
      deleteChannelFunction(channelId);
    }

    // deleteChannelFunction(channelId)
  },
  // Search List of channels with searchKey (searchKey  available in keywords)
  searchChannel: (searchKey) => {
    firebase
      .firestore()
      .collection("channels")
      .where("keywords", "array-contains", searchKey)
      .get()
      .then((snaps) => {
        // let result = snap.data();
        let results = snaps.docs.map((doc) => doc.data());
        // let newData = [];

        // newData[searchKey] = results;

        console.log("get search channels success", results);
        set({
          channelsStatus: channelsType.GETCHANNELS_SUCCESS,
          searchedChannels: results,
        });
      })
      .catch((error) => {
        console.log(`error getting user `, error.message);
      });
  },
  selectedChannelsOperations: async (
    operationType,
    updates,
    channelIdArray
  ) => {
    const channels = await firebase
      .firestore()
      .collection("channels")
      .where("channelId", "in", channelIdArray)
      .get();
    const batch = firebase.firestore().batch();

    if (operationType === "delete") {
      set({
        channelsStatus: channelsType.DELETESELECTEDCHANNELS_LOADING,
      });
      channels.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch
        .commit()
        .then(() => {
          console.log("delete selected channels success");
          set({
            channelsStatus: channelsType.DELETESELECTEDCHANNELS_SUCCESS,
          });
          // window.location.reload();
        })
        .catch((error) => {
          console.log("delete selected channels error", error.message);
          set({
            channelsStatus: channelsType.DELETESELECTEDCHANNELS_FAIL,
          });
        });
    } else if (operationType === "changeStatus") {
      set({
        channelsStatus: channelsType.UPDATESELECTEDCHANNELS_LOADING,
      });

      channels.forEach((doc) => {
        batch.update(doc.ref, { status: updates });
      });

      await batch
        .commit()
        .then(() => {
          console.log("updated selected channels success");
          set({
            channelsStatus: channelsType.UPDATESELECTEDCHANNELS_SUCCESS,
          });
          // window.location.reload();
        })
        .catch((error) => {
          console.log("updating selected channels error", error.message);
          set({
            channelsStatus: channelsType.UPDATESELECTEDCHANNELS_FAIL,
          });
        });
    }
  },
  // get channel ratings and reviews
  getChannelReview: (channelId) => {
    set({ channelReviewStatus: reviewsType.GETCHANNELREVIEWS_LOADING });

    firebase
      .firestore()
      .collectionGroup("reviewFeedback")
      .where("contentId", "==", channelId)
      .get()
      .then((snaps) => {
        let result = snaps.docs.map((doc) => doc.data());
        // let result = snap.data();
        console.log(`getChannelReview success`, result);
        set({
          channelReviews: result,
          channelReviewStatus: reviewsType.GETCHANNELREVIEWS_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(`getChannelReview error`, error.message);
        set({
          channelReviewStatus: reviewsType.GETCHANNELREVIEWS_FAIL,
          channelsError: error.message,
        });
      });
  },
  updateChannelReview: (reviewId, updates) => {
    set({ channelReviewStatus: reviewsType.UPDATECHANNELREVIEWS_LOADING });

    const reviewRef = firebase
      .firestore()
      .collectionGroup("reviewFeedback")
      .where("reviewId", "==", reviewId);

    reviewRef.get().then((snapshot) => {
      console.log(`channel review snapshot`, snapshot);
      snapshot.forEach((docItem) => {
        const docRef = docItem.ref;

        docRef
          .update({ ...updates, updatedAt: timestamp })
          .then(() => {
            console.log(`channel review update successfull`);
            set({
              channelReviewStatus: reviewsType.UPDATECHANNELREVIEWS_SUCCESS,
            });
          })
          .catch((error) => {
            console.log(`channel review update error`, error.message);
            set({
              channelReviewStatus: reviewsType.UPDATECHANNELREVIEWS_FAIL,
              channelReviewError: error.message,
            });
          });
      });
    });
  },
  deleteChannelReview: (reviewId) => {
    set({ channelReviewStatus: reviewsType.UPDATECHANNELREVIEWS_LOADING });

    const reviewRef = firebase
      .firestore()
      .collectionGroup("reviewFeedback")
      .where("reviewId", "==", reviewId);

    reviewRef.get().then((snapshot) => {
      console.log(`channel review snapshot`, snapshot);
      snapshot.forEach((docItem) => {
        const docRef = docItem.ref;

        docRef
          .delete()
          .then(() => {
            console.log(`channel review update successfull`);
            set({
              channelReviewStatus: reviewsType.UPDATECHANNELREVIEWS_SUCCESS,
            });
          })
          .catch((error) => {
            console.log(`channel review update error`, error.message);
            set({
              channelReviewStatus: reviewsType.UPDATECHANNELREVIEWS_FAIL,
              channelReviewError: error.message,
            });
          });
      });
    });
  },
}));

export default useChannelsStore;
