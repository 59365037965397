import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import firebase from "firebase";
import "./scss/style.scss";
import Interactivity from "./views/overlays/Interactivity";
import InteractivityAnalytics from "./views/overlays/InteractivityAnalytics";
import NewInteractivity from "./views/overlays/NewInteractivity";
import VodInteractivity from "./views/overlays/VodInteractivity";
import VodNewInteractivity from "./views/overlays/VodNewInteractivity";
// Import CryptoJS
// import cryptoJS from "crypto-js";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Email App
// const TheEmailApp = React.lazy(() => import('./views/apps/email/TheEmailApp'));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

// Encryption function
// const encrypt = (data, key) => {
//   return cryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
// };
// // Decryption function
// const decrypt = (encryptedData, key) => {
//   const bytes = cryptoJS.AES.decrypt(encryptedData, key);
//   const decryptedData = bytes.toString(cryptoJS.enc.Utf8);
//   return JSON.parse(decryptedData);
// };
// Example usage
const originalData = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASURMENT_ID,
};
// const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
// // Encrypt the data
// const encryptedData = encrypt(originalData, encryptionKey);
// console.log("Encrypted data:", encryptedData);
// Decrypt the data
// const decryptedData = decrypt(encryptedData, encryptionKey);
// console.log("Decrypted data:", decryptedData);

class App extends Component {
  componentDidMount() {
    this.mainFirebase();
  }

  mainFirebase() {
    const firebaseConfig = originalData;
    // Initialize Firebase

    if (firebase.apps.length <= 0) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            {/* channel interactivity */}
            <Route
              path="/channels/interactivity/:id/new"
              name="New Interactivity"
              render={(props) => <NewInteractivity {...props} />}
            />
            <Route
              path="/channels/interactivity/:id/edit/:type"
              name="Edit Interactivity"
              render={(props) => <NewInteractivity {...props} />}
            />
            <Route
              path="/interactivity/:id"
              name="Interactivity"
              render={(props) => <Interactivity {...props} />}
            />
            <Route
              path="/interactivity-analytics/:id"
              name="Interactivity Analytics"
              render={(props) => <InteractivityAnalytics {...props} />}
            />
            {/* vodInteractivity */}
            <Route
              path="/resources/vodInteractivity/:id/new"
              name="New Interactivity"
              render={(props) => <VodNewInteractivity {...props} />}
            />
            <Route
              path="/resources/vodInteractivity/:id/edit/:type"
              name="Edit Interactivity"
              render={(props) => <VodNewInteractivity {...props} />}
            />
            <Route
              path="/vodInteractivity/:id"
              name="VodInteractivity"
              render={(props) => <VodInteractivity {...props} />}
            />
            {/* episode vodInteractivity */}
            <Route
              path="/resources/episode/:episodeId/vodInteractivity/:id/new"
              name="New Episode VodInteractivity"
              render={(props) => <VodNewInteractivity {...props} />}
            />
            <Route
              path="/resources/episode/:episodeId/vodInteractivity/:id/edit"
              name="Edit Episode VodInteractivity"
              render={(props) => <VodNewInteractivity {...props} />}
            />
            <Route
              path="/resource/:id/episode/:episodeId/vodInteractivity"
              name="Episode VodInteractivity"
              render={(props) => <VodInteractivity {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
