import React, { useEffect } from "react";
import { CCard, CCardHeader, CCardBody } from "@coreui/react";
import { CChart } from "@coreui/react-chartjs";

const ProfileInteractionsCharts = (profileInteractions) => {
  const options = {
    maintainAspectRatio: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 5,
        },
      },
    },
  };

  const interactionsArray = profileInteractions.profileInteractions;

  if (!Array.isArray(interactionsArray)) {
    console.error(
      "Profile interactions data is not an array:",
      interactionsArray
    );
    return (
      <div>
        No profile interactions data available. (SELECT AN INTERACTIVITY)
      </div>
    );
  }

  const hourlyInteractionCount = new Array(24).fill(0);

  const deviceCount = {
    Android: 0,
    iOS: 0,
    "Apple TV": 0,
    "Android TV": 0,
  };

  const regionCount = {
    UAE: 0,
    Algeria: 0,
    Others: 0,
  };

  interactionsArray.forEach((interaction) => {
    const { updatedAt, type } = interaction;

    // Extract device and region details based on interaction type
    let device, os, region;

    if (type === "quiz") {
      const quizKey = Object.keys(interaction).find((key) =>
        key.startsWith("question-")
      );
      if (quizKey) {
        const quizData = interaction[quizKey];
        device = quizData.device;
        os = quizData.os;
        region = quizData.region;
      }
    } else {
      device = interaction.device;
      os = interaction.os;
      region = interaction.region;
    }

    if (updatedAt && updatedAt.seconds) {
      const date = new Date(updatedAt.seconds * 1000);
      const hour = date.getHours();
      hourlyInteractionCount[hour] += 1;

      // Categorize devices
      if (device === "mobile" && os === "android") {
        deviceCount.Android += 1;
      } else if (device === "mobile" && os === "ios") {
        deviceCount.iOS += 1;
      } else if (device === "tv" && os === "apple") {
        deviceCount["Apple TV"] += 1;
      } else if (device === "tv" && os === "android") {
        deviceCount["Android TV"] += 1;
      }

      // Categorize regions
      if (region === "UAE") {
        regionCount.UAE += 1;
      } else if (region === "Algeria") {
        regionCount.Algeria += 1;
      } else {
        regionCount.Others += 1;
      }
    } else {
      console.error("Invalid updatedAt format for interaction:", interaction);
    }
  });

  const hourlyLabels = Array.from({ length: 24 }, (_, index) =>
    index.toString().padStart(2, "0") + ":00"
  );

  const pieChartLabels = Object.keys(deviceCount);
  const pieChartData = Object.values(deviceCount);

  const regionChartLabels = Object.keys(regionCount);
  const regionChartData = Object.values(regionCount);

  return (
    <>
      <CCard>
        <CCardHeader>Interaction Timeline (Hourly)</CCardHeader>
        <CCardBody>
          <CChart
            type="line"
            options={options}
            labels={hourlyLabels}
            datasets={[
              {
                label: "No. of Interactions",
                fill: false,
                lineTension: 0.15,
                backgroundColor: "rgba(75,192,192,0.4)",
                borderColor: "rgba(231, 29, 139,0.9)",
                data: hourlyInteractionCount,
              },
            ]}
          />
        </CCardBody>
      </CCard>

      <CCard>
        <CCardHeader>Interaction Devices</CCardHeader>
        <CCardBody>
          <CChart
            type="pie"
            options={options}
            labels={pieChartLabels}
            datasets={[
              {
                label: "Device Interactions",
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#32A22B"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#32A22B"],
                data: pieChartData,
              },
            ]}
          />
        </CCardBody>
      </CCard>

      <CCard>
        <CCardHeader>Interaction Regions</CCardHeader>
        <CCardBody>
          <CChart
            type="doughnut"
            options={options}
            labels={regionChartLabels}
            datasets={[
              {
                label: "Regions",
                backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
                hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
                data: regionChartData,
              },
            ]}
          />
        </CCardBody>
      </CCard>
    </>
  );
};

export default ProfileInteractionsCharts;
